<template>
  <div class="sections" v-if="sections.length >0">
    <div v-for="(section, i) of sections" :key="i" class="section">
      <div v-if="section.title" class="title">{{ section.title }}</div>
      <ul class="docs">
        <li v-for="(doc, j) of section.docs" :key="j" class="doc">
          <a :href="doc.url">
            <span class="text">{{ doc.text }}</span>
            <img :src="require('./down.svg')" class="icon" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sections: {
      type: Array,
      default: () => [
        {
          docs: [
            {
              url: "google.com",
              text: "Rogito notarile"
            },
            {
              url: "google.com",
              text: "Titolo autorizzativo"
            },
            {
              url: "google.com",
              text: "Agibilità"
            }
          ]
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.sections {
  .section {
    margin-bottom: 50px;

    .title {
      font-weight: bold;
      font-size: 17px;
      line-height: 22px;
      color: black;
      margin-bottom: 26px;
    }

    .docs {
      list-style: none;
      margin: 0;
      padding: 0;

      .doc {
        background: #ffffff;
        border-radius: 20px;
        margin-bottom: 20px;

        a {
          color: var(--black);
          text-decoration: none;
          display: grid;
          grid-template-columns: 1fr auto;
          padding: 17px 0;
          padding-right: calc(27px);
          position: relative;

          .text {
            font-weight: 450;
            font-size: 17px;
            line-height: 22px;
            padding-left: calc(24px);
            display: flex;
            align-items: center;
          }

          .icon {
            display: block;
          }
        }
      }
    }
  }
}
</style>
