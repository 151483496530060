<template>
  <div class="inner">
    <Navigation text="Uso e manutenzione" />

    <div class="wrapper">
      <InnerHeader
        title="Uso e manutenzione"
        description="Di seguito trovi tutta la documentazione per l’uso e la manutenzione di tutti gli apparecchi presenti in casa."
      />

      <div class="sections">
        <VideoSection
          v-for="(section, i) of sections"
          :key="i"
          :title="section.title"
          :description="section.description"
          :video="section.video"
          :docs="section.docs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import InnerHeader from "@/components/InnerHeader";
import VideoSection from "@/components/VideoSection";

import { loadApartment } from "@/plugins/contentful";

export default {
  components: {
    Navigation,
    InnerHeader,
    VideoSection
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data: () => ({
    sections: [
      {
        title: "Sala polifunzionale",
        video: "243244233",
        docs: [
          {
            name: "Manuale tecnico",
            url: "google.com"
          }
        ]
      },
      {
        title: "Sala polifunzionale",
        docs: [
          {
            name: "Manuale tecnico",
            url: "google.com"
          },
          {
            name: "Manuale tecnico",
            url: "google.com"
          }
        ]
      }
    ]
  }),

  async beforeRouteEnter(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.sections = apartment.condo.fields.maintenance.map(appliance => ({
        title: appliance.fields.name,
        description: appliance.fields.description,
        docs: !appliance.fields.docs ? [] :
          appliance.fields.docs.map(doc => ({
            name: doc.fields.title,
            url: doc.fields.file.url
          })),
        ...(appliance.fields.video
          ? { video: appliance.fields.video.fields.file.url }
          : {})
      }));
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.sections = apartment.condo.fields.maintenance.map(appliance => ({
        title: appliance.fields.name,
        description: appliance.fields.description,
        docs: appliance.fields.docs.map(doc => ({
          name: doc.fields.title,
          url: doc.fields.file.url
        })),
        ...(appliance.fields.video
          ? { video: appliance.fields.video.fields.file.url }
          : {})
      }));
    });
  }
};
</script>

<style lang="scss" scoped>
.inner {
  padding-bottom: 100px;
  box-sizing: border-box;

  .contact {
    margin-bottom: 66px;
  }

  .wrapper {
    box-sizing: border-box;
    padding: 0 30px;
  }

  .sections {
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 60px;
    }
  }
}
</style>
