<template>
  <div class="inner">
    <Navigation text="Condominio" />

    <div class="wrapper">
      <InnerHeader
        title="Condominio"
        description="Alcuni materiali e informazioni riferiti al condominio sempre a tua disposizione."
      />

      <div class="wrap">
        <div>
          <SeparatedDocList :sections="condoDocs" />

          <BigButton
            class="contact"
            text="CONTATTA L’AMMINISTRATORE"
            @click.native="$router.push(`/${code}/contatta-amministratore`)"
          />
        </div>

        <DocList :sections="periodicDocs" />
      </div>

      <div class="emotional" v-if="emotionals.length > 0">
        <EmotionalText
          v-for="(block, i) of emotionals"
          :key="i"
          :image="block.image"
          :title="block.title"
          :text="block.text"
          :inverted="i % 2 !== 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import InnerHeader from "@/components/InnerHeader";
import SeparatedDocList from "@/components/SeparatedDocList";
import BigButton from "@/components/BigButton";
import DocList from "@/components/DocList";
import EmotionalText from "@/components/EmotionalText";

import { loadApartment } from "@/plugins/contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default {
  components: {
    Navigation,
    InnerHeader,
    SeparatedDocList,
    BigButton,
    DocList,
    EmotionalText
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data: () => ({
    condoDocs: [
      {
        docs: []
      }
    ],
    periodicDocs: [
      {
        title: "Documenti periodici",
        docs: []
      }
    ],
    emotionals: [
      {
        title: "Sala polifunzionale",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque tincidunt tincidunt maximus. Aenean ut aliquam magna. Fusce in nibh quis nulla ultricies ultrices vitae at ante. Proin eget varius ex, in molestie erat. Proin pretium lobortis tellus, sit amet maximus eros. Vestibulum quis libero purus. Sed varius sagittis gravida. Praesent vel dui fermentum, rutrum dui id, vulputate tellus. Nulla ac lacinia mi. Phasellus et convallis odio.",
        image: "//via.placeholder.com/330x205"
      },
      {
        title: "Sala polifunzionale",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque tincidunt tincidunt maximus. Aenean ut aliquam magna. Fusce in nibh quis nulla ultricies ultrices vitae at ante. Proin eget varius ex, in molestie erat. Proin pretium lobortis tellus, sit amet maximus eros. Vestibulum quis libero purus. Sed varius sagittis gravida. Praesent vel dui fermentum, rutrum dui id, vulputate tellus. Nulla ac lacinia mi. Phasellus et convallis odio.",
        image: "//via.placeholder.com/330x205"
      }
    ]
  }),
  async beforeRouteEnter(to, from, next) {
    const apartment = await loadApartment(to.params.code);

    next(vm => {
      vm.$data.condoDocs[0].docs = !apartment.condo.fields.docs ? [] : apartment.condo.fields.docs.map(doc => ({
        url: doc.fields.file.url,
        text: doc.fields.title
      }));

      vm.$data.periodicDocs[0].docs = !apartment.condo.fields.recurringDocs ? [] : apartment.condo.fields.recurringDocs.map( doc => ({
          url: doc.fields.file.url,
          text: doc.fields.title
        })
      );

      vm.$data.emotionals = !apartment.condo.fields.emotionals ? [] : apartment.condo.fields.emotionals.map(d => ({
        title: d.fields.title,
        text: documentToHtmlString(d.fields.text),
        image: d.fields.picture.fields.file.url
      }));
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.condoDocs[0].docs = apartment.condo.fields.docs.map(doc => ({
        url: doc.fields.file.url,
        text: doc.fields.title
      }));

      vm.$data.periodicDocs[0].docs = apartment.condo.fields.recurringDocs.map(
        doc => ({
          url: doc.fields.file.url,
          text: doc.fields.title
        })
      );

      vm.$data.emotionals = apartment.condo.fields.emotionals.map(d => ({
        title: d.fields.title,
        text: documentToHtmlString(d.fields.text),
        image: d.fields.picture.fields.file.url
      }));
    });
  }
};
</script>

<style lang="scss" scoped>
.inner {
  padding-bottom: 100px;
  box-sizing: border-box;

  .contact {
    margin-bottom: 66px;
  }

  .wrapper {
    box-sizing: border-box;
    padding: 0 30px;

    .wrap {
      @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px;
      }
    }
  }
}
</style>
